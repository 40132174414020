<template>
  <div class="politicBox-container">
      <div class="top">
          <div class="title">{{title}}</div>
          <div class="subtitle">{{subtitle}}</div>
      </div>
      <div class="bottom">
          <div class="item" v-for="(item,i) in shortedPolicies" :key="i">
              <div class="depart">{{item.depart}}:</div>
              <div>{{item.title}}</div>
              <div class="hover-box">
                  <div class="depart">{{item.depart}}:</div>
                  <div class="full-title">{{item.fullTitle}}</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            require:true
        },
        subtitle:{
            type:String,
            require:true
        },
        policies:{
            type:Array,
            require:true
        }
    },
    computed:{
        shortedPolicies:function(){
            const tempArray = this.policies.map((item)=>{
                const out = {};
                if (item.title.length >= 28){
                    out.title = `${item.title.slice(0,28)}...`;
                }else{
                    out.title = item.title
                }
                out.depart = item.depart;
                out.fullTitle = item.title;
                return out;
            })
            return tempArray;
        }
    }
}
</script>

<style lang="less" scoped>
    .politicBox-container{
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items:center;
        background-color:#fff;
        border-radius:1rem;
        .top{
            flex: 0 0 5rem;
            min-width:17rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .title{
                font-weight:900;
                color:#2b2b2b;
                font-size:2.4rem;
                flex:0 0 auto;
            }
            .subtitle{
                flex:0 0 auto;
                font-size:1.4rem;
                color:#AC9455;
            }
        }
        .bottom{
            flex:0 1 12rem;
            width: 75%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            .item{
                flex:0 0 1.4rem;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                font-family: Microsoft YaHei;
                font-size:1.2rem;
                width:100%;
                position:relative;
                .depart{
                    color:#333;
                    flex:0 0 auto;
                }
                div:nth-of-type(2){
                    color:#999;
                    flex: 0 1 auto;
                    margin-left:1rem;
                }
                &:hover .hover-box{
                    display: flex;
                }
                .hover-box{
                    position:absolute;
                    left:0;
                    width:100%;
                    height:auto;
                    justify-content: flex-start;
                    align-items: flex-start;
                    background-color:white;
                    box-shadow:0 0.4rem 1rem rgba(0,0,0,0.15);
                    display:none;
                    z-index:1;
                    div:nth-of-type(2){
                        color:#999;
                        flex:0 1 auto;
                        margin-left:1rem;
                        text-align:left;
                    }
                }
            }
        }
    }
</style>