<template>
  <div class="newsItem-container" @click="toContent" :data-id="newsObj.id" :data-type="newsObj.type">
    <div class="left">
      <div class="top">
        <div class="title">{{ newsObj.title }}</div>
        <div class="infos">
          <div class="type">
            {{ newsObj.type == 1 ? "行业资讯" : "公司动态" }}
          </div>
          <div class="vertical-divider">|</div>
          <div class="time">发布于 {{ newsObj.create_time }}</div>
        </div>
      </div>
      <div class="bottom">
        <div class="intro">
          {{ newsObj.introduction }}
        </div>
      </div>
    </div>
    <div class="right">
      <img :src="newsObj.image_url" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newsObj: {
      type: Object,
      require: true,
    },
  },
  methods:{
    toContent(e){
      const id = e.currentTarget.getAttribute('data-id');
      this.$emit('toNewsContent',id);
    }
  }
};
</script>

<style lang="less" scoped>
.newsItem-container {
  width: 100%;
  min-height: 100%;
  max-width:120rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: space-around;
  @media screen and (max-width: 1500px) {
    flex-wrap: wrap;
    padding:10% 0;
  }
  border-bottom: 2px solid #f0f0f0;
  .left {
    flex: 1 1 70%;
    height: 100%;
    min-height:36.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: flex-start;
    .top {
      flex: 0 0 6.3rem;
      width:80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: flex-start;
      .title {
        color: #333;
        font-size: 2.4rem;
        text-align: left;
        line-height:3.6rem;
      }
      .infos {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.2rem;
        color: #999;
        .vertical-divider {
          margin: 0 2rem;
        }
      }
    }
    .bottom {
      flex: 0 0 9.1rem;
      width: 72rem;
      .intro {
        text-align: left;
        font-size: 1.6rem;
        color: #666;
        line-height: 2;
      }
    }
  }
  .right {
    flex: 0 0 35rem;
    height: 24.4rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>