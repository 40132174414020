<template>
  <div class="news-container">
    <div class="banner">
      <img src="@/assets/images/news/banner.jpg" alt="" />
    </div>
    <div class="nav">
      <div :class="navActivity ? 'active' : ''" @click="changeTag(true)">
        行业资讯
      </div>
      <div :class="!navActivity ? 'active' : ''" @click="changeTag(false)">
        公司动态
      </div>
    </div>
    <div class="news-items" v-if="navActivity">
      <div 
      class="item" 
      v-for="(item, index) in industryNewsShow" 
      :key="index">
        <NewsItem :newsObj="item" @toNewsContent="toNewsContent"></NewsItem>
      </div>
    </div>
    <div class="news-items" v-else>
      <div
        class="item"
        v-for="(item, index) in companyDynamicsShow"
        :key="index"
      >
        <NewsItem :newsObj="item" @toNewsContent="toNewsContent"></NewsItem>
      </div>
    </div>
    <div class="pagination">
      <Pagination
        :maxPage="maxPage"
        :current="current"
        @pageChange="pageChange"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { getCompanyDynamics } from "@/utils/api";
import NewsItem from "@/components/NewsItem";
import Pagination from "@/components/Pagination";
export default {
  components: {
    NewsItem,
    Pagination,
  },
  data() {
    return {
      navActivity: true,
      companyDynamics: [],
      companyDynamicsShow: [],
      industryNews: [],
      industryNewsShow: [],
      current: 1,
      tag: 1,
    };
  },
  methods: {
    pageChange(current) {
      this.current = current;
      this.companyDynamicsShow = this.companyDynamics.slice(
        3 * (current - 1),
        3 * current
      );
      this.industryNewsShow = this.industryNews.slice(
        3 * (current - 1),
        3 * current
      );
    },
    changeTag(status) {
      this.navActivity = status;
      this.current = 1;
      this.pageChange(this.current);
    },
    toNewsContent(id){
      this.$emit('toNewsContent',id);
    }
  },
  computed:{
    maxPage(){
      if (this.navActivity){
        return Math.ceil(this.industryNews.length/3);
      }else{
        return Math.ceil(this.companyDynamics.length/3);
      }
    }
  },
  created() {
    getCompanyDynamics({ page: 1, page_size: 999, type: 2 }).then((res) => {
      this.companyDynamics = res.data.body.data;
      this.companyDynamicsShow = res.data.body.data.slice(0, 3);
    });
    getCompanyDynamics({ page: 1, page_size: 999, type: 1 }).then((res) => {
      this.industryNews = res.data.body.data;
      this.industryNewsShow = res.data.body.data.slice(0, 3);
    });
    console.log(this.$route.query)
    if (this.$route.query != {}){
      switch(this.$route.query.type){
        case '1':
          this.navActivity = true;
          break;
        case '2':
          this.navActivity = false;
          break;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.news-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  .banner {
    flex: 0 0 100%;
    height: 43.6rem;
    margin-top: 6.2rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav {
    flex: 0 0 100%;
    height: 8.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    border-bottom: 2px solid #f0f0f0;
    div {
      color: #333;
      font-size: 2.4rem;
      font-weight: 900;
      font-family: Songti SC;
      text-align: center;
      flex: 0 1 13.5rem;
      cursor:pointer;
      &.active {
        color: #006369;
      }
    }
  }
  .news-items {
    min-height: 109.8rem;
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    .item {
      flex: 0 1 62.5%;
      min-height: 36.5rem;
    }
  }
  .pagination {
    height: 20rem;
    width: 100%;
    Pagination {
      width: 100%;
      height: 100%;
    }
  }
}
</style>