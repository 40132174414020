<template>
  <div class="aboutus-container">
    <div class="banner">
      <img src="@/assets/images/aboutus/banner.jpg" alt="banner" />
    </div>
    <div class="contents">
      <div class="top">
        <CoincidentText
          mainText="荣誉资质"
          shadowText="HONORARY QUALIFICATION"
          mainPos="center"
        ></CoincidentText>
      </div>
      <div class="mid">
        <div class="left">
          <img src="@/assets/images/aboutus/certificates.png" alt="证书" />
        </div>
        <div class="right">
          <div class="top">
            <div>■ 高新技术企业证书</div>
            <div>■ 四川省环境保护协会副会长单位</div>
            <div>■ 建设项目环境监理等级确认证书</div>
            <div>■ 碳披露平台系统等多个软件著作权</div>
          </div>
          <div class="bottom">
            <img src="@/assets/images/aboutus/target.png" alt="" />
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="sectionTitle">
            <CoincidentText
              mainText="人才招聘"
              shadowText="TALENT RECRUITMENT"
            ></CoincidentText>
          </div>
          <div class="welcome">欢迎加入我们！</div>
          <div class="bottom">
            <div>地址：四川省成都市武侯区二环路南四段51号莱蒙都会1栋706</div>
            <div>联系人：林女士</div>
            <div>电话：028-86203079</div>
            <div>邮编：610041</div>
            <div>网站：http://www.ioteis.com/</div>
            <div>邮箱：hb@ioteis.com</div>
          </div>
        </div>
        <div class="right">
          <div class="top">
            <img src="@/assets/images/aboutus/model.png" alt="" />
          </div>
          <div class="shadow"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoincidentText from "@/components/CoincidentText";
export default {
  components: {
    CoincidentText,
  },
};
</script>

<style lang="less" scoped>
.aboutus-container {
  width: 100%;
  min-height: 185.8rem;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  .banner {
    flex: 0 0 100%;
    margin-top: 6.2rem;
    max-height: 44rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .contents {
    flex: 0 0 100%;
    max-width: 120rem;
    min-height: 146rem;
    display: flex;
    justify-content: center;
    align-content: space-evenly;
    flex-wrap: wrap;
    .top {
      flex: 0 0 100%;
      height: auto;
    }
    .mid {
      flex: 0 0 100%;
      min-height: 47rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .left {
        height: auto;
        flex: 0 1 74.3rem;
        img {
          width: 100%;
          height: auto;
        }
      }
      .right {
        flex: 0 1 31rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .top {
          width: 100%;
          flex: 0 0 12.3rem;
          color: #333;
          font-size: 1.4rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
        }
        .bottom {
          flex: 0 0 31.2rem;
          width: auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .bottom {
      flex: 0 0 100%;
      height: 42rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      align-content: space-between;
      .left {
        flex: 0 0 40rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        .sectionTitle {
          width: 100%;
          
        }
        .welcome {
          color: #006369;
          font-size: 2rem;
        }
        .bottom {
          flex: 0 0 20rem;
          width: 100%;
          color: #999;
          font-size: 1.4rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
        }
      }
      .right {
        flex: 0 0 68rem;
        height:100%;
        position: relative;
        .top {
          width: 66.8rem;
          height: 40.3rem;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 1.2rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .shadow{
            width:66.8rem;
            height:40.3rem;
            background-color:#006369;
            position:absolute;
            z-index: 0;
            left:0;
            top:1.3rem;
        }
      }
    }
  }
}
</style>