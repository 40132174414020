<template>
  <div class="products-container">
    <div class="banner">
      <img src="@/assets/images/products/banner.jpg" alt="" />
    </div>
    <div class="content">
      <div class="carbonEmission">
        <ProductItem
          mainText="碳减排"
          shadowText="CARBON EMISSION"
          discription="`业务介绍：
■   公司为政府提供碳达峰碳中和政策咨询服务。为企业和园区提供全流程的碳排放管理咨询服务，包括碳盘查摸底、碳资产管理、碳达峰碳中和路径规划、行业低碳标准制定、低碳管理体系建设、碳排放信息披露。同时为政府、企业、园区提供低碳信息化建设服务。
■“碳达峰”“碳中和”政策和知识普及培训服务。
■   为政府及工业园区、行业协会编制“碳达峰”“碳中和”规划及行动方案；采用先进降碳技术、碳交易、碳金融、气候投融资等手段为政府机构提供技术及决策支持；为政府机构核查本地排放单位的碳减排成效、碳中和行动进展，提供第三方评价等技术服务。
■   开展碳普惠项目，量化公众的低碳行为减碳量，引导低碳消费和低碳生活等。
■ “碳达峰”“碳中和”政策和知识培训服务。编制企业“碳达峰”“碳中和”规划与行动方案。协助企业完成碳排放权配额履约的碳资产管理服务。助力企业实现碳中和的低碳融资方案咨询、设计。为企业实现碳中和提供降碳技术措施的咨询服务。对工程建设项目开展碳排放水平评估等。`
       "
          :introList="introLists.carbon"
        ></ProductItem>
      </div>
      <div class="techEvaluation">
        <ProductItem
          mainText="技术评估"
          shadowText="TECHNICAL EVALUATION"
          discription="`业务介绍：
■ 公司以从事过省级环评评估工作的人员为班底，参照省生态环境厅评估工作流程，以省、市级专家库为依托，建立具有行业特点的优质专家邀请机制。根据企业特点随机抽取行业专家现场踏勘和评审，形成评审意见，待环评文件修改完善后公司出具评估意见，为地方生态环境局审批提供技术支撑。
■ 公司作为“政府第三方技术服务机构”，自2017年起先后承接了攀枝花、德阳、双流、龙泉驿区、邛崃等市、县级生态环境局委托的共计约500余件规划环评和建设项目环评文件技术评估咨询服务。`"
          :introList="introLists.technique"
        ></ProductItem>
      </div>
      <div class="poluteAdmission">
        <ProductItem
          mainText="排污许可"
          shadowText="POLLUTANT DISCHARGE PERMIT"
          discription="`业务介绍：
          ■ 公司技术骨干曾参与国家、省级排污许可政策、课题及标准等研究工作，可准确理解国家政策法规，精准掌握各行业排污许可审核要点难点及“一证式”管理要求。 
          ■ 公司已承接多个市、县级生态环境局委托的上千个排污许可证和执行报告审核技术服务工作。 
          ■ 公司为省内钢铁、火电、焦化等多个重点行业的龙头企业提供排污许可填报和证后管理服务。`"
          :introList="introLists.pollutant"
        ></ProductItem>
      </div>
      <div class="environKeeper">
        <ProductItem
          mainText="环保管家"
          shadowText="ENVIRONMENTAL HOUSEKEEPER"
          discription="`业务介绍：
■   为园区或企业提供一站式环保技术咨询服务，针对园区及企业的不同特点，量身制定不同的服务内容，提供全生命周期环保咨询服务和污染治理解决方案，提出高效化、精细化和专业化环保建议，提高环保管理人员管理水平等。
■   建立能决策、能说清、能监管、能预警的园区智慧环保平台，通过建立污染源动态排放清单、完善前端智能化预警监管体系建设、建立智能化预警溯源机制。实现基于环保大数据分析的线上、线下全方位的新型的环保管家服务。典型应用在建立污染源全生命周期动态档案、建立与执法业务相结合双随机应用、实现引导式的现场检查模式、提供智能化的手机终端，实现动态统计和决策分析等方面。`"
          :introList="introLists.environKeeper"
        >
        </ProductItem>
      </div>
      <div class="UAV">
        <ProductItem
          mainText="无人机踏勘、航测"
          shadowText="UAV RECONNAISSANCE and AERIAL SURVEY"
          discription="`业务介绍：
■   提供无人机现场踏勘、正射影像航测、倾斜摄影建模等服务，大幅降低人力、物力、时间成本。
■   应用于技术评估现场踏勘、入河排污口核排查、环境事故应急排查、环境监理工程测量、环保管家巡查巡检等诸多领域，高效助力各类环保业务。`"
          :introList="introLists.uav"
        >
        </ProductItem>
      </div>
      <div class="others">
        <div class="top">
          <div class="title">其他服务</div>
        </div>
        <div class="bottom">
          <div class="otherItem">
            <OtherItem
            title="环境监理"
            business="接受建设单位的委托，依据环境影响评价文件及其批复，在施工现场对污染防治和生态保护的情况进行检查，督促各项环保措施落到实处；对未按有关环境保护要求施工或建设的，责令建设单位限期改正，造成生态破坏的，督促采取补救措施或予以恢复。"
            achievement="金川奥依诺矿业有限公司业隆沟锂辉石矿采选尾工程、中国电建集团成都勘测设计研究院有限公司仁沐新高速公路">
            </OtherItem>
          </div>
          <div class="divider"></div>
          <div class="otherItem">
            <OtherItem
            title="土壤污染风险管控"
            business="提供土壤污染状况调查、土壤污染风险评估、风险管控方案编制、修复方案编制、风险管控效果评估、相关工程监理等服务。"
            achievement="自贡高新技术产业开发区管理委员会生态环境与应急管理局土壤污染状况调查服务">
            </OtherItem>
          </div>
          <div class="divider"></div>
          <div class="otherItem">
            <OtherItem
            title="环保信息化建设"
            business="依托丰富的环保工作经验，以先进技术为核心，以标准和规范体系、安全及运维体系、组织及管理体系为保障，打造以“环保云”平台为基础的环境日常管理、辅助决策支持、环境监管的信息化系统，实现“互联网+碳排放”，为不同需求用户提供定制化智慧管理和服务支撑。"
            achievement="金堂县工业园区部署完成园区网格巡检管理信息系统、石棉县工业园区环境地理信息系统、四川省公路院信息化管理平台">
            </OtherItem>
          </div>
          <div class="divider"></div>
          <div class="otherItem">
            <OtherItem
            title="突发事件应急预案"
            business="编制突发环境事件应急预案，并于生态环境主管部门备案，涉及行业包含化工、电镀、电子、电力、石化、家具、造纸、橡胶和塑料、印刷包装、制衣针织、皮革、制鞋、印染、表面处理、食品、药业、建材行业等"
            achievement="九寨沟垃圾处理厂阿坝州医疗废物集中处置中心（B）厂突发环境事件应急预案、九寨沟县生活垃圾处理厂突发环境事件应急预案、简阳张家岩水库突发环境事件应急预案">
            </OtherItem>
          </div>
          <div class="divider"></div>
          <div class="otherItem">
            <OtherItem
            title="入河排污口论证"
            business="按照法律法规及水资源保护要求，遵循水资源合理开发、节约使用、有效保护的原则，对排污口设置的合理性进行论证；为各级生态环境主管部门或流域管理机构审批入河排污口及建设单位合理设置入河排污口提供技术支持"
            achievement="甘孜州泸定县磨西镇生活污水处理厂入河排污口设置论证、凉山索玛（集团）有限责任公司入河排污口设置论证">
            </OtherItem>
          </div>
          <div class="divider"></div>
          <div class="otherItem">
            <OtherItem
            title="竣工环境保护验收"
            business="为企业提供建设项目竣工环保验收服务，协助企业自查各项环境保护措施落实情况和环保设施运行效果，为企业正式投运提供环保依据。涉及行业包含化工、电镀、电子、电力、石化、家具、造纸、橡胶和塑料、印刷包装、制衣针织、皮革、制鞋、印染、表面处理、食品、药业、建材行业、采掘等"
            achievement="会理县白草矿山项目、四川致远锂业2万吨年氯化锂1万吨年电池级单水氢氧化锂1万吨年电池级碳酸锂项目、成都九洲酿精酿啤酒有限公司原浆啤酒生产项目（一期）、成都崇州展新胶粘公司AMOLED可折叠柔性显示屏上屏体项目（一期）、金堂县成都耕源新材料有限公司高分子材料制品制造项目 （一期）">
            </OtherItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";
import OtherItem from "@/components/OtherItem";
export default {
  components: {
    ProductItem,
    OtherItem,
  },
  data() {
    return {
      introLists: {
        carbon: [
          [
            require("@/assets/images/products/碳减排-造纸行业.png"),
            "四川省造纸行业协会",
            "为四川省造纸行业协会提供行业双碳行动方案等政策咨询服务（编制全国首个造纸行业双碳行动方案）",
          ],
          [
            require("@/assets/images/products/碳减排-宜宾天原.png"),
            "宜宾天原集团股份有限公司",
            "为宜宾天原集团股份有限公司提供碳排放盘查、碳排放配额履约等碳资产管理咨询服务（编制碳排放盘查报告、碳配额履约方案等）",
          ],
          [
            require("@/assets/images/products/碳减排-川威集团.png"),
            "四川省川威集团有限公司",
            "为四川省川威集团有限公司下属矿山、水泥、物流版块产业提供双碳行动方案等政策咨询服务（编制矿山、水泥、物流产业双碳行动方案）",
          ],
          [
            require("@/assets/images/products/碳减排-崇州消费.png"),
            "崇州市工业服务中心",
            "为崇州消费电子产业园区提供园区双碳行动方案等政策咨询服务（编制四川首个工业园区双碳行动方案）。",
          ],
        ],
        technique: [
          [
            require("@/assets/images/products/技术评估-攀枝花.png"),
            "攀枝花市生态环境局环境影响评价技术评估",
            "我公司服务攀枝花市生态环境局环境影响评价技术评估累计2年，承担规划环评及建设项目环评文件技术评估咨询服务约90件，其中规划环评1件、报告书53件。",
          ],
          [
            require("@/assets/images/products/技术评估-成都邛崃.png"),
            "成都市邛崃生态环境局环境影响评价技术评估",
            "我公司服务邛崃生态环境局环境影响评价技术评估累计3~4年，截止目前，承担建设项目环评文件技术评估咨询服务近200件。",
          ],
          [
            require("@/assets/images/products/技术评估-成都龙泉.png"),
            "成都市龙泉驿生态环境局环境影响评价技术评估",
            "我公司服务龙泉驿生态环境局环境影响评价技术评估累计2年，承担建设项目环评文件技术评估咨询服务超过130余件。",
          ],
        ],
        pollutant: [
          [
            require("@/assets/images/products/排污许可-四川盛马.png"),
            "四川盛马化工股份有限公司排污许可证填报及证后管理",
            "协助四川盛马化工股份有限公司完成原油加工及石油制品制造、有机化学原料制造等行业类别排污许可证的申报，并协助排污许可证后管理。",
          ],
          [
            require("@/assets/images/products/排污许可-广安.png"),
            "广安2021年排污许可质量审核",
            "为广安市2021年排污许可证质量复核和排污许可执行报告规范性审核服务，审核排污许可证169家，执行报告107家，并对区县生态环境局排污许可审核人员开展业务培训。",
          ],
          [
            require("@/assets/images/products/排污许可-攀钢.png"),
            "攀钢集团西昌钢钒有限公司排污许可证填报及证后管理",
            "协助攀钢集团西昌钢钒有限公司及其下属公司完成炼铁、炼钢、煤焦化、火力发电、铁合金冶炼等行业类别排污许可证的申报，并协助排污许可证后管理。",
          ],
        ],
        environKeeper: [
          [
            require("@/assets/images/products/环保管家-成渝.png"),
            "成渝钒钛科技有限公司环保管家项目",
            "协助成渝钒钛科技有限公司及其下属公司完成炼铁、炼钢、煤焦化、火力发电、铁合金冶炼等行业类别排污许可证的申报，并协助排污许可证后管理。",
          ],
          [
            require("@/assets/images/products/环保管家-成都崇州.png"),
            "成都崇州经济开发区管理委员会“环保管家”工作机制服务项目",
            "我公司为成都崇州经济开发区管理委员会提供环保管家服务累计3年，每年排查园区企业超过300家，协助管委会为园区企业提供定制化“一厂一策”环保指导服务。",
          ],
          [
            require("@/assets/images/products/环保管家-南充.png"),
            "南充市生态环境局经开区分局环保管家项目",
            "我公司为南充市生态环境局经开区分局提供环保管家服务累计2年，每年对园区内的企业排查2次，提供全方位环保排查、技术诊断、入园项目环保咨询、环境管理档案建立",
          ],
          [
            require("@/assets/images/products/环保管家-明珠家具.png"),
            "明珠家具有限公司环保管家项目",
            "我公司长期以来一直为明珠家具有限公司提供环保设计、环保治理和环保管理等多方位咨询，形成五年战略合作。",
          ],
        ],
        uav: [
          [
            require("@/assets/images/products/无人机-快速巡查.png"),
            "快速巡查巡检",
            "无人机搭载高清视频采集设备，配合数据链路，对河湖水域、排污口，大气污染源等进行巡逻监控并记录GPS坐标，为相关部门开展治理工作提供可靠数据，弥补地面固定式监测站的巡查盲区。",
          ],
          [
            require("@/assets/images/products/无人机-现场踏勘.png"),
            "无人机现场踏勘",
            `无人机现场踏勘（图片右下角文字“汛期河道监控”换成“现场踏勘”）
            <br>简介：通过项目现场执飞，记录项目外环境关系、敏感点位等重要信息，并实时直播执飞影像。`,
          ],
          [
            require("@/assets/images/products/无人机-航测建模.png"),
            "无人机航测建模",
            "我公司无人机可搭载正射相机，在区、平原、城市等地开展航测，根据用户需求后期制作精度正射影像图（DOM）、数字线划图（DLG）、数字⾼程图（DEM）等。",
          ],
          [
            require("@/assets/images/products/无人机-后期视频.png"),
            "后期视频展示",
            "我公司拥有专业的后期制作，利用三维制作与实景拍摄结合、卫星影像标注动画、实拍视频标注动画等制作后期视频，有效满足各场景下，后期成果展示需求。",
          ],
        ],
      },
    };
  },
  mounted: function () {
    console.log(this.introLists);
  },
};
</script>

<style lang="less" scoped>
.products-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  .banner {
    flex: 0 0 43.6rem;
    width: 100%;
    margin-top:6.2rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    flex: 0 1 735rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .carbonEmission {
      flex: 0 1 125rem;
      width: 100%;
    }
    .techEvaluation {
      flex: 0 1 105rem;
      width: 100%;
    }
    .poluteAdmission {
      flex: 0 1 115rem;
      width: 100%;
    }
    .environKeeper {
      flex: 0 1 115rem;
      width: 100%;
    }
    .UAV{
      flex:0 0 100rem;
      width: 100%;
    }
    .others{
      flex:0 0 170rem;
      width:100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      .top{
        flex:0 1 21rem;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:#f2f3f5;
        .title{
          font-size:4.2rem;
          color:#333;
        }
      }
      .bottom{
        flex: 0 1 calc(100% - 25rem);
        max-width:120rem;
        display: flex;
        justify-content: flex-start;
        align-content: space-evenly;
        flex-wrap: wrap;
        .otherItem{
          flex:0 0 95%;
          height:auto;
        }
        .divider{
          flex:0 0 72.5%;
          height:1px;
          background-color:#f0f0f0;
          align-self:flex-start
        }
      }
    }
  }
}
</style>