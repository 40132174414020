<template>
  <div class="app-container">
    <div class="header">
      <NavigatorBar @navigateTo="navigateTo" :logoUrl="logoUrl"></NavigatorBar>
    </div>

    <div class="router-container">
      <router-view 
      @toNewsContent="toNewsContent"
      @navigateTo="navigateTo"
      @jumpToNews="jumpToNews"
      @toPolicyContent="toPolicyContent"
      @jump="jump"
      />
    </div>

    <div class="footer">
      <Footer :links="footerLinks"></Footer>
    </div>
  </div>
</template>

<script>
import NavigatorBar from "./components/NavigatorBar";
import Footer from "./components/Footer";
import { getFooterLinks } from "./utils/api";
import router from "./router/index.js"

export default {
  name: "App",
  components: {
    NavigatorBar,
    Footer,
  },
  data() {
    return {
      logoUrl: require("@/assets/icon/logo.png"),
      footerLinks: [],
    };
  },
  methods: {
    navigateTo(id) {
      switch (id) {
        case "1":
          console.log("case 1")
          router.push({name:'index'});
          break;
        case "2":
          console.log("case 2")
          router.push({name:"products"});
          break;
        case "3":
          router.push({name:"news"});
          break;
        case "4":
          router.push({name:"policies"});
          break;
        case "5":
          router.push({name:"aboutus"});
          break;
        case "6":
          router.push({name:"contactus"});
          break;
      }
    },
    jump(path){
      router.push({name:path})
    },
    toNewsContent(id){
      const data = {"id":id,'type':'news'};
      router.push({name:"content",query:data});
    },
    jumpToNews(type){
      const data = {'type':type}
      router.push({name:"news",query:data});
    },
    toPolicyContent(id){
      const data = {"id":id,'type':"policy"};
      router.push({name:"content",query:data});
    }
  },
  created() {
    getFooterLinks().then((res) => {
      this.footerLinks = res.data.body;
    });
  },
};
</script>

<style lang="less">
@import url(./css/reset.less);
:root {
  font-size: 12px;
  overflow-x: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, MicrosoftYaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  padding:0;
  margin:0;

  .header {
    width: 100%;
    min-height: 6.2rem;
    height: 6.2rem;
    position: fixed;
    z-index: 999;
  }

  .footer {
    width: 100%;
    height: 43.6rem;
  }

  .router-container {
    width: 100%;
    height: auto;
  }
}
</style>
