<template>
  <div class="productItem-container">
    <div class="content">
      <div class="top">
        <CoincidentText
          :mainText="mainText"
          :shadowText="shadowText"
          mainPos="center"
        ></CoincidentText>
      </div>
      <div class="discription">
        <div class="start">业务介绍：</div>
        <div class="disContent" v-for="(item, i) in disSet" :key="i">
          {{ item }}
        </div>
      </div>
      <div class="showBoxes">
          <Wrapper3D
          :introList="introList"
          ></Wrapper3D>
      </div>
    </div>
  </div>
</template>

<script>
import CoincidentText from "@/components/CoincidentText";
import Wrapper3D from "@/components/Wrapper3D";
export default {
  components: {
    CoincidentText,
    Wrapper3D,
  },
  computed: {
    disSet() {
      const dis = this.discription.split("■").map((item, i) => {
        let temp = item.trim();
        if (i > 0) {
          temp = `■   ${temp}`;
        }
        return temp;
      });
      // out.start = dis[0]
      dis.splice(0, 1);
      return dis;
    },
  },

  props: {
    introList:{
      type:Array,
      default: []
    },
    mainText: {
      type: String,
      require: true,
    },
    shadowText: {
      type: String,
      require: true,
    },
    discription: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.productItem-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    flex: 0 1 120rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .top {
      width: 100%;
      flex: 0 0 auto;
    }
    .discription {
      width: 90%;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 1.4rem;
      font-family: Microsoft YaHei;
      .start {
        color: #333;
        flex: 0 0 auto;
        line-height: 3.6rem;
      }
      .disContent {
        color: #999999;
        flex: 0 0 auto;
        width: 100%;
        line-height: 3.6rem;
        text-align: left;
      }
    }
    .showBoxes{
        margin-top:10rem;
        flex:0 0 50rem;
        width:100%;
    }
  }
}
</style>