<template>
  <div class="figureshowbox-container">
    <div class="left">
      <div class="title">{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
      <div class="paragraph">{{ context }}</div>
      <div class="quote">”</div>
    </div>
    <div class="right">
      <img :src="figureUrl" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    subtitle: {
      type: String,
      require: true,
    },
    context: {
      type: String,
      require: true,
    },
    figureUrl: {
      type: String,
      require: true,
    },
  },
};
</script>

<style lang="less" scoped>
.figureshowbox-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Microsoft YaHei;
  background-color: #fff;
  border-radius: 1rem;
  &:hover {
    box-shadow: 1rem 1rem 2rem 0rem rgba(85, 103, 161, 0.5);
  }
  .left {
    flex: 0 0 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    .title {
      color: #2b2b2b;
      font-weight: 800;
      font-size: 2.6rem;
      text-align: center;
    }
    .subtitle {
      color: #ac9455;
      font-size: 1.2rem;
      text-align: center;
    }
    .paragraph {
      width: 80.7%;
      text-align: justify;
      line-height:2.2rem;
      font-size:1.4rem;
    }
    .quote {
      text-align: center;
      color: #d4d4d4;
      font-size: 1.8rem;
    }
  }
  .right {
    flex: 0 0 50%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }
}
</style>