<template>
  <div class="index-container">
    <div class="banner">
      <Carousel
        :list="list"
        :carouselType="carouselType"
        navSize="1.4rem"
        specialType="specialBanner"
      >
        <template v-slot:item0>
          <div class="item">
            <img src="@/assets/images/index_01.jpg" alt="" />
          </div>
        </template>
        <template v-slot:item1>
          <div class="item">
            <img src="@/assets/images/index_02.jpg" alt="" />
          </div>
        </template>
      </Carousel>
    </div>
    <!-- 公司简介 -->
    <div class="intro">
      <div class="content">
        <div class="left">
          <CoincidentText
            mainText="公司简介"
            shadowText="COMPANY PROFILE"
            class="subtitle"
          ></CoincidentText>
          <div class="paragraph">
            四川爱欧特环保科技有限公司成立于2017年5月，注册资金500万元，是专业从事政府第三方技术服务、企业环保咨询服务、碳资产管理以及环保低碳技术研发与应用的高新技术企业。公司涉足环评技术评估、碳排放、排污许可、环保管家、环境监理、土壤污染风险管控、环保信息化建设等相关环保业务。公司目前近90%人员为专业技术人员，中高级职称占比60%，近50%的拥有硕士及以上学位，团队具有丰富的环保政策理论水平和实战经验，其工作业绩得到了省、市（州）生态环境主管部门一致好评，是省内较有影响力的环保综合服务企业。
          </div>
          <div class="button" @click="jump" data-url="aboutus">更多</div>
        </div>
        <div class="right">
          <img
            src="@/assets/images/index/index_compIntro_01.jpg"
            alt="公司简介图片"
            class="size1"
          />
        </div>
      </div>
    </div>
    <!-- 公司业务 -->
    <div class="services">
      <div class="content">
        <div class="top">
          <div class="left">
            <CoincidentText
              mainText="公司业务"
              shadowText="CORPORATE BUSINESS"
              class="title"
              shadowColor="rgba(172, 148, 85,0.3)"
            ></CoincidentText>

            <div class="context">
              专业从事政府第三方技术服务、企业环保咨询服务、碳资产管理以及环保低碳技术研发与应用的高新技术企业。
            </div>
          </div>
          <div class="right">
            <div class="button" @click="jump" data-url="products">更多</div>
          </div>
        </div>
        <div class="bottom">
          <div
            class="figure-box"
            v-for="(item, i) in servicesContents"
            :key="i"
          >
            <FigureShowBox
              :title="item.title"
              :subtitle="item.subtitle"
              :context="item.context"
              :figureUrl="item.figureUrl"
            ></FigureShowBox>
          </div>
        </div>
      </div>
    </div>
    <!-- 公司动态 -->
    <div class="dynamics">
      <div class="content">
        <div class="top">
          <CoincidentText
            mainText="公司动态"
            shadowText="COMPANY DYNAMICS"
            class="title"
            mainPos="center"
          ></CoincidentText>
        </div>
        <div class="bottom">
          <Carousel
            :list="dynamics"
            carouselType="fade"
            navSize="1.2rem;border:0.2rem solid #459089;box-sizing:border-box;"
            navColor="transparent"
            navPosition="center;transform:translatex(-10%)"
            specialType="specialContent"
            carouselBGC="#F2F3F5"
            @navigateTo="navigateTo"
          >
          </Carousel>
        </div>
      </div>
    </div>
    <!-- 政策标准 -->
    <div class="politics">
      <div class="content">
        <div class="top">
          <CoincidentText
            mainText="政策标准"
            shadowText="POLICY STANDARDS"
            class="title"
            shadowColor="rgba(172, 148, 85,0.3)"
            mainPos="center"
          ></CoincidentText>
        </div>
        <div class="mid">
          <div class="left">
            <PoliticBox
              title="双碳政策"
              subtitle="Double Carbon Policies"
              :policies="dCPolicies"
            ></PoliticBox>
          </div>
          <div class="right">
            <PoliticBox
              title="环保知乎"
              subtitle="Environmental Protection Knowledge"
              :policies="ePKPolicies"
            ></PoliticBox>
          </div>
        </div>
        <div class="bottom">
          <div class="button" @click="jump" data-url="policies">了解更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "../../components/Carousel";
import CoincidentText from "../../components/CoincidentText";
import FigureShowBox from "../../components/FigureShowBox";
import PoliticBox from "../../components/PoliticBox";
import router from "@/router";
import { getCompanyDynamics, getTopPolicies } from "@/utils/api";
export default {
  components: {
    Carousel,
    CoincidentText,
    FigureShowBox,
    PoliticBox,
  },
  data() {
    return {
      list: [
        require("@/assets/images/index_01.jpg"),
        require("@/assets/images/index_02.jpg"),
      ],
      carouselType: "easeInFade",
      servicesContents: [
        {
          title: "碳排放",
          subtitle: "Carbon Emission",
          context:
            "为政府提供碳达峰碳中和政策咨询服务。为企业和园区提供全流程的碳排放管理咨询服务，包括碳盘查摸底、碳资产管理、碳达峰碳中和路径规划、行业低碳标准制定、低碳管理体系建设、碳排放信息披露。同时为政府、企业、园区提供低碳信息化建设服务。",
          figureUrl: require("@/assets/images/index/index_business_01.jpg"),
        },
        {
          title: "技术评估",
          subtitle: "Technical Evaluation",
          context:
            "精准把握各行业排污许可证审核的关键技术要点及“一证式”管理服务。2017年起，为双流区、龙泉驿区、攀枝花、邛崃、德阳等地市、县生态环境局提供项目环境影响评价报告技术评估服务，承担的规划环评和建设项目环评文件技术评估咨询服务超500余件。",
          figureUrl: require("@/assets/images/index/index_business_02.jpg"),
        },
        {
          title: "排污许可",
          subtitle: "Pollutant Discharge Permit",
          context:
            "为各级生态环境部门提供排污许可证核发，已核发排污许可证及执行报告质量审核服务；为企业提供排污许可证申报和执行报告填报，及证后管理服务。为生态环境主管部门和企业提供排污许可相关技能培训和技术支持等工作。",
          figureUrl: require("@/assets/images/index/index_business_03.jpg"),
        },
        {
          title: "环保管家",
          subtitle: "Environmental Housekeeper",
          context:
            "为园区、企业提供一站式环保技术咨询服务，针对园区及企业的不同特点，精准定制服务内容。提供全生命周期环保咨询服务和污染治理解决方案，提出高效化、精细化和专业化环保建议，提高环保管理人员管理水平等。建立园区智慧环保平台，实现基于环保大数据分析的新型环保管家服务。",
          figureUrl: require("@/assets/images/index/index_business_04.jpg"),
        },
      ],
      // 公司动态数据获取
      dynamics: [],
      // 双碳政策
      dCPolicies: [],
      //环保知乎
      ePKPolicies: [],
    };
  },
  methods: {
    navigateTo(url) {
      router.push({ name: url });
    },
    jump(e) {
      const url = e.target.dataset.url;
      router.push({ name: url });
    },
  },
  created() {
    getCompanyDynamics({ page: 1, page_size: 5, type: 2 }).then((res) => {
      const resp = res.data.body.data;
      this.dynamics = resp;
    });
    getTopPolicies({ type: 1 }).then((res) => {
      const resp = res.data.body;
      const out = resp.map((item) => {
        const obj = { depart: "", title: "" };
        switch (item.type) {
          case 1:
            obj.depart = "政策规章";
            break;
          case 2:
            obj.depart = "标准指南";
            break;
          case 3:
            obj.depart = "新闻资讯";
            break;
          case 4:
            obj.depart = "信息披露";
            break;
        }
        obj.title = item.title;
        return obj;
      });
      this.dCPolicies = out;
    });
    getTopPolicies({ type: 101 }).then((res) => {
      const resp = res.data.body;
      const out = resp.map((item) => {
        const obj = { depart: "", title: "" };
        switch (item.type) {
          case 101:
            obj.depart = "法律";
            break;
          case 102:
            obj.depart = "法规";
            break;
          case 103:
            obj.depart = "规章";
            break;
          case 104:
            obj.depart = "国家标准";
            break;
          case 105:
            obj.depart = "地方标准";
            break;
          case 106:
            obj.depart = "规范性文件";
            break;
        }
        obj.title = item.title;
        return obj;
      });
      this.ePKPolicies = out;
    });
  },
};
</script>

<style lang="less" scoped>
.index-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .paragraph {
    font-size: 1.4rem;
    font-family: Microsoft YaHei;
    color: #999;
  }
  .button {
    background-color: #459089;
    width: 13.2rem;
    height: 4.4rem;
    line-height: 4.4rem;
    font-size: 1.4rem;
    letter-spacing: 0.3rem;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .banner {
    width: 100%;
    flex: 0 0 100vh;
    .item {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        animation: easeInFade 5s ease-out forwards, fade 1s ease-in forwards;
      }
      @keyframes fade {
        0% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes easeInFade {
        0% {
          transform: scale(1.5, 1.5);
        }
        100% {
          transform: scale(1, 1);
        }
      }
    }
  }

  .intro {
    flex: 0 1 86.4rem;
    display: flex;
    justify-content: center;
    align-content: center;
    .content {
      flex: 0 1 120rem;
      flex-wrap: wrap-reverse;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 960px) {
        justify-content: center;
        align-content: space-around;
      }
      .left {
        flex: 0 1 44rem;
        min-height: 43rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .subtitle {
          flex: 0 0 auto;
          width: 100%;
        }
        .paragraph {
          line-height: 3.6rem;
          text-align: left;
        }
      }
      .right {
        flex: 0 1 52rem;
        height: auto;
      }
    }
  }

  .services {
    width: 100%;
    flex: 0 1 106.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f3f5;
    .content {
      flex: 0 1 120rem;
      height: 88rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .top {
        flex: 0 1 15rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          flex: 0 1 90rem;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .title {
            width: 100%;
          }
          .context {
            width: 100%;
            color: #999;
            font-size: 2.2rem;
            line-height: 3.6rem;
            text-align: left;
          }
        }
      }
      .bottom {
        flex: 0 1 66rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        .figure-box {
          flex: 0 0 56.6rem;
          height: 31rem;
        }
      }
    }
  }

  // 公司动态
  .dynamics {
    flex: 0 1 87rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      flex: 0 1 120rem;
      height: 51.6rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .top {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .bottom {
        flex: 0 0 40rem;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }

  // 政策标准
  .politics {
    flex: 0 1 83.7rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#F2F3F5;
    .content {
      flex: 0 1 120rem;
      height: 61rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .top {
        flex: 0 0 auto;
        width: 100%;
      }
      .mid {
        width: 100%;
        flex: 1 0 34rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .left{
          flex:0 1 57rem;
          height:34rem;
        }
        .right{
          flex:0 1 57rem;
          height:34rem;
        }
      }
      .bottom {
        flex: 0 0 auto;
      }
    }
  }
}
</style>