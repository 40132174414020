<template>
  <div class="pagination-container">
    <div
      class="pager"
      v-for="(item, index) in pageList"
      :key="index"
      @click="clickHandler($event,item)"
      :data-index="index"
      :class="current == item ? 'active' : ''"
    >
      <div>{{ item }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    maxPage: {
      type: Number,
      require: true,
    },
    current:{
      type: Number,
      default: 1,
    }
  },
  computed: {
    pageList() {
      const maxPage = this.maxPage;
      if (!maxPage || maxPage <= 0) {
        return [];
      }
      if (!this.current) {
        this.current = 1;
      }
      const res = [];
      res.push('<');
      if (maxPage < 7) {
        for (let i = 1; i <= maxPage; i++) {
          res.push(i);
        }
      } else {
        if (this.current <= 4) {
          for (let i = 1; i <= 5; i++) {
            res.push(i);
          }
          res.push("...");
          res.push(maxPage);
        } else if (4 < this.current && this.current < maxPage - 3) {
          res.push(1);
          res.push("...");
          for (let i = -1; i <= 1; i++) {
            res.push(this.current + i);
          }
          res.push("...");
          res.push(maxPage);
        } else {
          res.push(1);
          res.push("...");
          for (let i = maxPage - 4; i <= maxPage; i++) {
            res.push(i);
          }
        }
      }
      res.push('>');
      return res;
    },
  },
  lifetimes: {
    attachted() {
      if (this.maxPage > 0) {
        this.current = 1;
      }
    },
  },
  methods: {
    clickHandler(e,page) {
      let jumpPage = page;
      if (jumpPage == "<"){
        jumpPage = this.current-1 >0 ? this.current-1:this.current;
      }else if (jumpPage == ">"){
        jumpPage = this.current+1 < this.maxPage? this.current+1:this.current;
      }else if (jumpPage == "..."){
        const dataIndex = e.currentTarget.getAttribute("data-index");
        console.log(dataIndex,this.current)
        if (dataIndex < 4){
          console.log('a')
          if (4 < this.current && this.current < this.maxPage-3){
            jumpPage = this.current -2;
          }else{
            jumpPage = this.maxPage -5;
          }
        }else{
          console.log('b')
          if (4 < this.current && this.current < this.maxPage-3){
            jumpPage = this.current +2;
          }else{
            jumpPage = 6;
          }
        }
      }
      console.log(jumpPage)

      if (jumpPage == this.current){
        return
      }else{
        this.$emit("pageChange",jumpPage);
      }
      

    },
  },
};
</script>

<style lang="less" scoped>
.pagination-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .pager {
    cursor: pointer;
    background-color: #f4f4f5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0 2rem;
    font-size: 1.4rem;
    color: #999;
    &.active {
      background-color: #006369;
      color: #fff;
    }
  }
}
</style>