import request from "./request"

export async function getFooterLinks() {
    const resp = await request.get(
        "/api/cms/footer-links/"
    )
    return resp
}

export async function getCompanyDynamics(data) {
    const resp = await request.get(
        `/api/cms/news/`, { params: data }
    )
    return resp
}

export async function getPolicies(data) {
    const resp = await request.get("/api/cms/policy/", { params: data })
    return resp
}

export async function getTopPolicies(data){
    const resp = await request.get("/api/cms/policy/top/",{params:data})
    return resp
}

export async function getNewsContent(data){
    const resp = await request.get("/api/cms/news/content/",{params:data})
    return resp
}

export async function getPolicyContent(data){
    const resp = await request.get("/api/cms/policy/content/",{params:data})
    return resp
}

export async function postMessage(data){
    const resp = await request.post("/api/cms/contact/",data)
    return resp
}