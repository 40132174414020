import Index from "@/views/index"
import Aboutus from "@/views/aboutus"
import Products from "@/views/products"
import News from "@/views/news"
import Policies from "@/views/policies"
import Contactus from "@/views/contactus"
import Content from "@/views/content"
import DoubleCarbon from "@/views/dycarbon"
import EnvironKnowledge from "@/views/environknowledge"

export default [
    {path:"/", name:'index',component: Index},
    {path:"/products",name:'products',component:Products},
    {path:"/news",name:'news',component:News},
    {path:"/policies",name:'policies',component:Policies},
    {path:"/aboutus",name:'aboutus',component:Aboutus},
    {path:"/contactus",name:'contactus',component:Contactus},
    {path:"/content",name:'content',component:Content},
    {path:"/doublecarbon",name:'doublecarbon',component:DoubleCarbon},
    {path:"/environknowledge",name:'environknowledge',component:EnvironKnowledge}
    // {path:"/:catchAll(.*)",redirect:"/"}
]