<template>
  <div class="footer-container">
    <div class="top">
      <div class="left">
        <div class="contents">
          <div class="title">四川爱欧特环保科技有限公司</div>
          <p class="address">地址：四川省成都市武侯区二环路南四段51号莱蒙都会1栋706</p>
          <p class="address">电话：028-86203079</p>
          <p class="address">邮编：610041</p>
          <p class="address">
            网站：<a href="http://www.ioteis.com/" class="c0"
              >http://www.ioteis.com/</a
            >
          </p>
          <p class="address">邮箱：hb@ioteis.com</p>
          <p class="address">
            <span>微信公众号 : IOT智慧环保 </span
            ><img src="../../assets/icon/ewm.png" alt="二维码示意图标" />
            <img
              src="../../assets/icon/iot_code.png"
              alt="爱欧特二维码"
              class="qr_code hidden"
            />
          </p>
        </div>
      </div>

      <div class="right">
        <div class="contents">
          <div class="title">友情链接</div>
          <a
            class="address"
            target="_blank"
            v-for="(item, i) in links"
            v-bind:key="i"
            :href="item.url"
            ><span>{{ item.name }}</span
            >{{ item.url }}</a
          >
        </div>
      </div>
    </div>
    <div class="bottom">
      <span class="putOnRecord"
        >版权所有 © 四川爱欧特环保科技有限公司 2015-2025 |
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
          >蜀ICP备17024887号-1</a
        ></span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: function () {
        return [
          {
            name: "生态环境部",
            url: "https://www.mee.gov.cn/",
          },
          {
            name: "国家发展和改革委员会",
            url: "https://www.ndrc.gov.cn/",
          },
          {
            name: "四川省生态环境厅",
            url: "http://sthjt.sc.gov.cn/",
          },
          {
            name: "环境影响评价网",
            url: "http://www.china-eia.com/",
          },
          {
            name: "全国排污许可管理信息平台公开端",
            url: "http://permit.mee.gov.cn/",
          },
          {
            name: "全国建设项目环境影响评价管理信息平台",
            url: "http://114.251.10.205/#/pub-message",
          },
        ];
      },
    },
  },
};
</script>

<style lang="less" scoped>
.footer-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .top {
    height: 84.4%;
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #2c2c2c;
    color: white;
    font-size: 1.2rem;

    .title {
      font-size: 1.8rem;
      color: #4f4f4f;
      margin-bottom: 2rem;
    }

    a {
      color: white;
    }

    .left {
      flex: 1 1 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 80%;

      .contents {
        width: auto;
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }

      .address:last-of-type {
        position: relative;
      }

      .address img {
        position: absolute;
        left: 17rem;
      }

      .address .qr_code {
        position: absolute;
        top: -15rem;
        left: 17rem;

        &.hidden {
          display: none;
        }
      }

      .address img:hover + .qr_code {
        display: block;
      }

      .address .qr_code:hover {
        display: block;
      }
    }

    .right {
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 80%;

      .contents {
        margin-right: 15%;
        width: auto;
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        a > span {
          margin-right: 2rem;
          display: inline-block;
        }
      }
    }
  }

  .bottom {
    height: 15.6%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #242424;
    color: #505050;
    a {
      color: #505050;
    }
  }
}
</style>