<template>
  <div class="policyItem-container">
    <div class="left">
      <img :src="typeLogos[obj.type]" alt="typelogo" />
    </div>
    <div class="right">
      <div class="type" @click="jump" :data-type="obj.type">{{ obj.typeName }}</div>
      <div class="discription" @click="toContent" :data-id="obj.id">{{ obj.sortTitle }}
          <div class="full-discription">{{obj.title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      require: true,
    },
  },
  lifetimes: {
    mounted() {
      console.log("aa");
      console.log(this.obj);
    },
  },
  data() {
    return {
      typeLogos: {
        1: require("@/assets/images/policies/政策规章.png"),
        2: require("@/assets/images/policies/标准指南.png"),
        3: require("@/assets/images/policies/新闻资讯.png"),
        4: require("@/assets/images/policies/信息披露.png"),
        101: require("@/assets/images/policies/法律.png"),
        102: require("@/assets/images/policies/法规.png"),
        103: require("@/assets/images/policies/规章.png"),
        104: require("@/assets/images/policies/国家标准.png"),
        105: require("@/assets/images/policies/地方标准.png"),
        106: require("@/assets/images/policies/规范性文件.png"),
      },
    };
  },
  methods:{
    toContent(e){
        const id = e.currentTarget.getAttribute("data-id");
        this.$emit("toPolicyContent",id);
    }
  }
};
</script>

<style lang="less" scoped>
.policyItem-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .left {
    margin-right: 3.6rem;
    flex: 0 0 8.8rem;
    height: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right{
      flex: 0 0 32rem;
      min-height:7.2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .type{
          font-size:3rem;
          color:#2b2b2b;
          font-weight:700;
      }
      .discription{
          color:#999;
          font-size:1.2rem;
          position:relative;
          &:hover .full-discription{
              display: block;
              cursor:pointer;
          }
          .full-discription{
              display: none;
              position: absolute;
              background-color:antiquewhite;
              top:0;
              left:0;
              text-align: left;
          }
      }
  }
}
</style>