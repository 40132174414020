<template>
  <div class="carousel-container">
    <div
      class="myCarousel"
      :class="carouselClasses[carouselType]"
      :style="`background-color:${carouselBGC}`"
    >
      <ul class="list" v-if="specialType == `none`">
        <li
          v-for="(item, i) in list"
          v-bind:key="i"
          :class="{
            active: i == currentPage,
          }"
        >
          <div class="item">
            <img
              :src="item"
              class="banner"
              :style="`width:${imgWidth};height:${imgHeight}`"
            />
            <slot name="otherAnimate"></slot>
          </div>
        </li>
      </ul>
      <ul class="list" v-if="specialType == `specialBanner`">
        <li
          v-for="(item, i) in list"
          v-bind:key="i"
          :class="{
            active: i == currentPage,
          }"
        >
          <slot :name="`item${i}`"></slot>
        </li>
      </ul>
      <ul class="list scontent" v-if="specialType == `specialContent`">
        <li
          v-for="(item, i) in list"
          v-bind:key="i"
          :class="{
            active: i == currentPage,
          }"
        >
          <div class="item">
            <div class="left">
              <div class="title">{{ item.title }}</div>
              <div class="context">{{ item.introduction }}</div>
              <div class="button" @click="jump" data-url="news">更多</div>
            </div>
            <div class="right">
              <img :src="item.image_url" alt="" />
            </div>
          </div>
        </li>
      </ul>
      <div class="nav" :style="`text-align:${navPosition}`">
        <span
          v-for="(item, i) in navList"
          v-bind:key="i"
          :style="`width:${navSize};height:${navSize};margin:0 ${navMargin};background-color:${
            item == currentPage ? navActiveColor : navColor
          }`"
          :data-id="item"
          @click="setCurrentPage"
          @mouseenter="pause"
          @mouseleave="autoPlay"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
const carouselClasses = {
  fade: "carousel_fade",
  animate: "carousel_animate",
  easeInFade: "carousel_easeInFade",
};
export default {
  props: {
    carouselType: {
      type: String,
      default: "fade",
      require: true,
    },
    list: {
      type: Array,
      require: true,
    },
    navPosition: {
      type: String,
      default: "center",
    },
    navSize: {
      type: String,
      default: "1rem",
    },
    navActiveColor: {
      type: String,
      default: "#135f57",
    },
    navColor: {
      type: String,
      default: "#aaa",
    },
    auto: {
      type: Boolean,
      default: true,
    },
    autoDuration: {
      type: Number,
      default: 10000,
    },
    specialType: {
      type: String,
      default: "none",
    },
    carouselBGC: {
      type: String,
      default: "#333",
    },
  },
  computed: {
    listLen: function () {
      return this.list.length;
    },
    navList: function () {
      const tempLen = this.list.length;
      const res = [];
      for (let i = 0; i < tempLen; i++) {
        res.push(i);
      }
      return res;
    },
    navMargin: function () {
      const size = parseFloat(this.navSize);
      const unit = this.navSize.match(/\D/g).slice(1).join("");
      let res = `1rem`;
      switch (unit) {
        case "rem":
          res = `${2 * size}rem`;
          break;
        case "px":
          res = `${0.2 * size}rem`;
          break;
      }
      return res;
    },
  },
  data() {
    return {
      carouselClasses: carouselClasses,
      currentPage: 0,
      timer: null,
    };
  },
  methods: {
    setCurrentPage(e) {
      const id = e.target.dataset.id;
      this.currentPage = id;
    },

    pause() {
      clearInterval(this.timer);
    },

    autoPlay() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        if (this.currentPage < this.listLen - 1) {
          this.currentPage++;
        } else {
          this.currentPage = 0;
        }


      }, this.autoDuration);
    },
    jump(e){
      const jumpUrl = e.target.dataset.url;
      this.$emit("navigateTo",jumpUrl);
    }
  },
  created() {
    if (this.auto) {
      this.autoPlay();
    }

    document.addEventListener("visibilitychange", (e) => {
      document.hidden && clearInterval(this.timer);
      document.hidden || (this.auto && this.autoPlay());
    });
  },
};
</script>

<style lang="less" scoped>
.carousel-container {
  width: 100%;
  height: 100%;
}
.myCarousel {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #333;
}

.myCarousel ul li .item {
  width: 100%;
  height: 100%;
}

.myCarousel ul li .item img {
  width: 100%;
  height: 100%;
}

.myCarousel .scontent li {
  width: 100%;
  height: 100%;
  .item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: warp;
    .left {
      flex: 0 1 47.5rem;
      height: 30.6rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .title {
        color: #2b2b2b;
        font-weight: 900;
        font-size: 2.4rem;
        flex: 0 0 auto;
        width: 100%;
        text-align: left;
      }
      .context {
        flex: 0 0 auto;
        width: 100%;
        color: #898989;
        font-size: 1.4rem;
        line-height: 3.6rem;
        text-align: left;
      }
      .button {
        background-color: #459089;
        width: 13.2rem;
        height: 4.4rem;
        line-height: 4.4rem;
        font-size: 1.4rem;
        letter-spacing: 0.3rem;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
    }
    .right {
      flex: 0 1 53rem;
      height: 34rem;
    }
  }
}
/* fade */
.carousel_fade ul {
  display: block;
  height: 100%;
  width: 100%;
}
.carousel_fade li {
  height: 100%;
  width: 100%;
  position: absolute;
  display: none;
}
.carousel_fade li.active {
  display: block;
}

.myCarousel li .banner {
  width: 100%;
  height: 100%;
  animation: fade 2s ease-in forwards;
}

/* animate */
.carousel_animate ul {
  display: flex; /* 变成弹性盒模型 */
  height: 100%;
  position: absolute;
  left: 0;
}

/* easeInFade */
.carousel_easeInFade ul {
  display: block;
  width: 100%;
  height: 100%;
}
.carousel_easeInFade li {
  height: 100%;
  width: 100%;
  position: absolute;
  display: none;
}

.carousel_easeInFade li.active {
  display: block;
}
.carousel_easeInFade li .banner1 {
  animation: easeInFade 5s ease-out forwards, fade 1s ease-in forwards;
}

/* 按钮 */
.myCarousel .btn {
  width: 25px;
  height: 35px;
  background: rgba(0, 0, 0, 0.15);

  position: absolute;
  top: 50%;
  margin-top: -17.5px;

  color: #fff;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
}
.myCarousel .prev {
  left: 0;
  border-radius: 0 18px 18px 0; /* 左上角 右上角 右下角 左下角 */
}
/* .carousel .prev i{
    position: relative;
    left: -3px;
} */
.myCarousel .next {
  right: 0;
  border-radius: 18px 0 0 18px;
}

/* 导航 */
.myCarousel .nav {
  position: absolute;
  bottom: 10px;

  width: 100%;
  padding: 0 10px;
  box-sizing: border-box; /* 怪异盒模型，盒模型的尺寸就是width与height，不会受padding border影响 */

  text-align: right;
}
.myCarousel .nav span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 20px;
  cursor: pointer;
}
</style>