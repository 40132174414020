<template>
  <div class="content-container">
    <div class="routes">
      <div class="index" @click="jump" data-id="1">首页</div>
      <div class="next">></div>
      <div
        class="type"
        v-if="contents.route == 'news'"
        :data-type="contents.type"
        @click="jumpToNews"
      >
        {{ contents.type == 1 ? "行业资讯" : "公司动态" }}
      </div>
      <div
        class="type"
        v-else
        @click="jumpToPolicies"
        :data-type="contents.type"
      >
        {{ contents.type < 100 ? "双碳政策" : "环保知乎" }}
      </div>
      <div class="next">></div>
      <div class="title">{{ contents.title }}</div>
    </div>
    <div class="heads">
      <div class="title">{{ contents.title }}</div>
      <div class="subtitle">
        <div
          class="type"
          v-if="contents.route == 'news'"
          :data-type="contents.type"
          @click="jump"
        >
          {{ contents.type == 1 ? "行业资讯" : "公司动态" }}
        </div>
        <div
          class="type"
          v-else
          @click="jumpToPolicies"
          :data-type="contents.type"
        >
          {{ contents.type < 100 ? "双碳政策" : "环保知乎" }}
        </div>
        <div class="dot">·</div>
        <div class="time" v-if="contents.route=='news'">发布于 {{ contents.create_time }}</div>
        <div class="time" v-else>{{contents.introduction}}</div>
      </div>
    </div>
    <!-- <div class="image">
        <img :src="contents.image_url" alt="">
    </div> -->
    <div class="content" v-html="contents.content"></div>
  </div>
</template>

<script>
import { getNewsContent, getPolicyContent } from "@/utils/api.js";
export default {
  data() {
    return {
      contents: {},
    };
  },
  mounted() {
    const params = this.$route.query;
    let param = {}
    switch (params.type) {
      case "news":
        param = {id:params.id}
        getNewsContent(param).then((res) => {
          console.log(res.data.body);
          const contents = res.data.body;
          contents.route = "news";
          this.contents = contents;
        });
        break;
      case "policy":
        param = {id:params.id}
        getPolicyContent(param).then((res) => {
          console.log(res.data.body)
          const contents = res.data.body;
          contents.route = "policies";
          this.contents = contents;
        });
    }
  },
  methods: {
    jump(e) {
      const id = e.currentTarget.getAttribute("data-id");
      this.$emit("navigateTo", id);
    },
    jumpToNews(e) {
      const type = e.currentTarget.getAttribute("data-type");
      this.$emit("jumpToNews", type);
    },
  },
};
</script>

<style lang="less" scoped>
.content-container {
  max-width: 120rem;
  height: 100%;
  padding-top: 6.2rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  flex-wrap: wrap;
  .routes {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    height: 3.6rem;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.4rem;
    color: #333;
    .index {
      cursor: pointer;
    }
    .type {
      cursor: pointer;
    }
    .next {
      margin: 0 1rem;
    }
  }
  .heads {
    margin: 5rem 0;
    min-height: 8rem;
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #333;
      font-size: 4rem;
      font-weight: 700;
    }
    .subtitle {
      width: 100%;
      display: flex;
      color: #333;
      font-size: 1.4rem;
      justify-content: center;
      & div {
        background-origin: border-box;
        background-color: #ccc;
      }
      .dot {
        padding: 0 1rem;
      }
    }
  }
  //   .image{
  //       flex:0 0 100%;
  //       height:auto;
  //       img{
  //           width:60%;
  //           height:auto;
  //       }
  //   }
  .content {
    flex: 0 0 100%;
    height: auto;
    color: #333;
    font-size: 1.6rem;
    line-height: 3.2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: left;
    text-align-last: left;
  }
}
</style>