<template>
  <div class="coincidentText-container" :style="`justify-content:${mainPos}`">
      <div :style="`font-size:${mainSize};color:${mainColor}`">{{mainText}}</div>
      <div :style="`font-size:${shadowSize};color:${shadowColor}`">{{shadowText}}</div>
  </div>
</template>

<script>
export default {
    computed:{
        shadowTop(){
            return `${(parseFloat(this.mainSize)-parseFloat(this.shadowSize))/2}rem`
        }
    },
    props:{
        mainText:{
            type:String,
            require:true,
        },
        shadowText:{
            type:String,
            require:true,
        },
        mainSize:{
            type:String,
            default:'4.2rem'
        },
        shadowSize:{
            type:String,
            default:'2.8rem'
        },
        mainPos:{
            type:String,
            default:'flex-start'
        },
        mainColor:{
            type:String,
            default:'#333'
        },
        shadowColor:{
            type:String,
            default:'#EAF0F3'
        }
    }
}
</script>

<style lang="less" scoped>
    .coincidentText-container{
        width:auto;
        position:relative;
        display: flex;
        align-items:center;
        font-family: songti SC;
        div:nth-of-type(1){
            z-index:2;
            font-weight:900;
        }
        div:nth-of-type(2){
            position:absolute;
            letter-spacing: 0.2rem;
            font-family: Source Han Sans CN;
            z-index:1;
        }
    }

</style>