<template>
  <div class="wrapper3d-container">
    <div class="content" :style="`transform: translateZ(-10rem) rotateY(${-current*60}deg)`">
      <div
        class="item"
        v-for="(item, i) in list"
        :key="i"
        :style="`transform:rotatey(${
          (360 / len) * (i - 1)
        }deg) translateZ(50rem)`"
      >
        <div class="top">
          <img :src="item[0]" alt="" />
        </div>
        <div class="bottom">
          <div class="title">{{ item[1] }}</div>
          <div class="dis" v-html="item[2]">
          </div>
        </div>
      </div>
    </div>
    <div class="leftArrow" @click="rotate(-1)">&lt;</div>
    <div class="rightArrow" @click="rotate(1)">&gt;</div>
  </div>
</template>

<script>
export default {
  computed: {
    len() {
      return this.list.length;
    },
    list() {
      const out = this.introList;
      let count = 0;
      while (out.length < 6) {
        out.push(out[count]);
        count++;
      }
      return out;
    },
  },
  props: {
    introList: {
      type: Array,
      default: [],
    },
  },
  data(){
    return {
      current: 1,
      lock:false,
    }
  },
  methods:{
    rotate(plus){
      if (this.lock){
        return;
      }
      this.lock = true;
      this.current += plus;
      setTimeout(()=>{
        this.lock=false;
      },1000)
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper3d-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  perspective: 200rem;
  perspective-origin:50% 50%;
  .leftArrow{
    position:absolute;
    font-size:5rem;
    color:#aaa;
    font-weight: 900;
    left:(20%);
    bottom:50%;
    cursor:pointer;
    transition:all 0.3s;
    &:hover{
      transform: scale(1.5,1.5);
    }
  }
  .rightArrow{
    position:absolute;
    font-size:5rem;
    color:#aaa;
    font-weight: 900;
    right:(20%);
    bottom:50%;
    cursor:pointer;
    transition:all 0.3s;
    &:hover{
      transform: scale(1.5,1.5);
    }
  }
  
  .content {
    flex: 0 0 100%;
    width:100%;
    height: 100%;
    position: absolute;
    transform-style:preserve-3d;
    transform:translateZ(-10rem) rotateY(60deg);
    transition:all 1s;
    
    .item {
      position: absolute;
      top: 0;
      left: calc(50% - 15rem);
      width: 30rem;
      height: 46rem;
      border-radius: 6px;
      backface-visibility: hidden;
      box-shadow:0 1rem 2rem rgba(104, 183, 197, 0.3);
      .top{
        width:100%;
        height:27rem;
        img{
          width:100%;
          height:100%;
        }
      }
      .bottom{
        width:100%;
        height:calc(100% - 27rem);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color:#F7F8FB;
        .title{
          flex: 0 0 auto;
          color:#2b2b2b;
          font-weight:900;
          font-size:1.6rem;
          width:75%;
        }
        .dis{
          flex: 0 0 auto;
          width:22rem;
          text-align: left;
          font-size:1.2rem;
          color:#666;
          line-height:2.4rem;
        }
      }
    }
  }
}
</style>