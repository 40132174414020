<template>
  <div class="otherItem-container">
    <div class="title">
      {{ title }}
    </div>
    <div class="content">
      <div class="paragraph">
        <div class="start">业务介绍：</div>
        <div class="dis" v-html="business"></div>
      </div>
      <div class="paragraph">
        <div class="start">典型业绩：</div>
        <div class="dis" v-html="achievement"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    business: {
      type: String,
      require: true,
    },
    achievement: {
      type: String,
      require: true,
    },
  },
};
</script>

<style lang="less" scoped>
.otherItem-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .title {
    flex: 0 0 auto;
    width: 100%;
    text-align: left;
    font-size: 2.9rem;
    color: #2b2b2b;
    font-weight:600;
    line-height:8rem;
  }
  .content {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .paragraph {
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 1.2rem;
      line-height: 3.6rem;
      .start {
        color: #333;
        flex:0 0 auto;
      }
      .dis {
        color: #999;
        text-align: left;

      }
    }
  }
}
</style>