<template>
  <div class="navigator-container">
    <div class="logo">
      <img :src="logoUrl" />
    </div>
    <div class="navBar">
      <div class="navs">
        <div
          data-id="1"
          @click="jump"
          :class="{ disable: getCurrent === 1, active: getCurrent === 1 }"
        >
          首页
        </div>
        <div
          data-id="2"
          @click="jump"
          :class="{ disable: getCurrent === 2, active: getCurrent === 2 }"
        >
          产品服务
        </div>
        <div
          data-id="3"
          @click="jump"
          :class="{ disable: getCurrent === 3, active: getCurrent === 3 }"
        >
          新闻中心
        </div>
        <div
          data-id="4"
          @click="jump"
          :class="{ disable: getCurrent === 4, active: getCurrent === 4 }"
        >
          政策标准
        </div>
        <div
          data-id="5"
          @click="jump"
          :class="{ disable: getCurrent === 5, active: getCurrent === 5 }"
        >
          关于我们
        </div>
        <div
          data-id="6"
          @click="jump"
          :class="{ disable: getCurrent === 6, active: getCurrent === 6 }"
        >
          联系我们
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logoUrl:{
      type:String,
      require:true
    },
  },
  data(){
    return {
      path:"/"
    }
  },
  computed:{
    getCurrent(){
      switch(this.path){
        case "/":
          return 1;
          break;
        case "/products":
          return 2;;
          break;
        case "/news":
          return 3;
          break;
        case "/policies":
          return 4;
          break;
        case "/aboutus":
          return 5;
          break;
        case "/contactus":
          return 6;
          break;
      }
    }
  },
  methods:{
    jump(e){
      const jumpId = e.target.dataset.id;
      this.$emit("navigateTo",jumpId)
    }
  },
  watch:{
    $route(e){
      this.path = e.path;
    }
  }
};
</script>

<style lang="less" scoped>
.navigator-container {
  font-size:1.4rem;
  width: 100%;
  height: 100%;
  min-height:62px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #006369;
  color: #fff;
  .logo {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      flex: 0 0 105px;
      height: 39px;
    }
  }

  .navBar {
    flex: 1 1 650px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    .navs {
      flex:0 0 650px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height:100%;
      div{
          flex:1 1 auto;
          height:100%;
          line-height:400%;
          box-sizing:border-box;
          text-align: center;
          cursor:pointer;
          &.active{
              border-bottom: 2px solid #94D0CB;
              color:#94D0CB;
          }
          &.disable{
              cursor:not-allowed;
          }
          &:hover{
              color:#67eee3;
          }
      }
    }
  }
}
</style>