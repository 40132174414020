<template>
  <div class="contactus-container">
    <div class="banner">
      <img src="@/assets/images/contactus/banner.png" alt="banner" />
    </div>
    <div class="contents">
      <div class="contacts">
        <div>地址：四川省成都市武侯区二环路南四段51号莱蒙都会1栋706</div>
        <div>联系人：林女士</div>
        <div>电话：028-86203079</div>
        <div>邮编：610041</div>
        <div>网站：http://www.ioteis.com/</div>
        <div>邮箱：hb@ioteis.com</div>
      </div>
      <div class="message-box">
        <div class="leaveMessage">
          <div class="head">留下您的信息</div>
          <form class="myForm" :model="message">
            <div class="name">
              <input
                type="text"
                name="name"
                placeholder="您的名字"
                style="color: #c2c2c2"
                onfocus="this.style.color = '#333';"
                onreset="this.style = 'color:#c2c2c2'"
                required="required"
                v-model="message.name"
              />
              <div class="alert" v-show="alert.name">请输入您的名字</div>
            </div>
            <div class="phone">
              <input
                class="phone"
                type="text"
                name="phone"
                placeholder="您的电话号码"
                style="color: #c2c2c2"
                onfocus="this.style = '#333333';"
                onreset="this.style = 'color:#c2c2c2'"
                required="required"
                v-model="message.phone"
              />
              <div class="alert" v-show="alert.phone">请输入您的电话</div>
            </div>
            <div class="content">
              <textarea
                class="content"
                name="content"
                placeholder="您的留言"

                required="required"
                v-model="message.content"
              ></textarea>
              <div class="alert" v-show="alert.content">请输入您的留言</div>
            </div>
            <div class="button-box">
              <input type="reset" value="重置" class="renew button" />
              <input
                type="submit"
                value="提交"
                class="submit button"
                @click="submitMessage"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postMessage } from "@/utils/api.js";
export default {
  data() {
    return {
      message: {
        name: "",
        phone: "",
        content: "",
      },
      alert: {
        name: false,
        phone: false,
        content: false,
      },
    };
  },
  methods: {
    submitMessage(e) {
      e.preventDefault();
      const flag = this.validateMessage(this.message);
      if (!flag) {
        return;
      }
      postMessage(this.message).then((res) => {
        if (res.data.code == 0) {
          this.message = {
            name: "",
            phone: "",
            content: "",
          };
        }
      });
    },
    validateMessage(message) {
      let flag = false;
      if (!message.name) {
        this.alert.name = true;
        flag = true;
        setTimeout(() => {
          this.alert.name = false;
        }, 10000);
      }
      if (!message.phone) {
        this.alert.phone = true;
        flag = true;
        setTimeout(() => {
          this.alert.phone = false;
        }, 10000);
      }
      if (!message.content) {
        this.alert.content = true;
        flag = true;
        setTimeout(() => {
          this.alert.content = false;
        }, 10000);
      }
      if (flag) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contactus-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  .banner {
    max-height: 43.6rem;
    margin-top: 6.2rem;
    flex: 0 0 100%;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  .contents {
    flex: 0 0 100%;
    max-width: 120rem;
    min-height: 122rem;
    display: flex;
    justify-content: center;
    align-content: space-evenly;
    flex-wrap: wrap;
    .contacts {
      flex: 0 0 105rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      color: #999;
      font-size: 1.4rem;
      height: 20rem;
    }
    .message-box {
      flex: 0 0 100%;
      height: 76rem;
      background-color: #f3f3f3;
      display: flex;
      justify-content: center;
      align-items: center;
      .leaveMessage {
        flex: 0 0 100%;
        max-width: 105rem;
        height: 63rem;
        display: flex;
        justify-content: flex-start;
        align-content: space-between;
        flex-wrap: wrap;
        .head {
          color: #333;
          font-size: 3rem;
          flex: 0 0 100%;
          text-align: left;
        }
        .myForm {
          flex: 0 0 100%;
          max-width: 105rem;
          min-height: 56rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-content: space-between;
          font-size: 1.4rem;
          .name,
          .phone {
            flex: 0 0 100%;
            height: 6.8rem;
            input {
              width: 100%;
              height: 100%;
              border: none;
              outline: none;
              padding: 0 1rem;
              box-sizing: border-box;
            }
            .alert {
              color: red;
              font-size: 1.4rem;
              text-align: left;
            }
          }
          .content {
            flex: 0 0 100%;
            height: 27rem;
            textarea {
              box-sizing: border-box;
              font-size: 1.4rem;
              line-height: 3.6rem;
              width: 100%;
              height: 100%;
              border: none;
              outline: none;
              resize: none;
              padding: 0 1rem;
              overflow: auto;
            }
            .alert {
              color: red;
              font-size: 1.4rem;
              text-align: left;
            }
          }
          .button-box {
            flex: 0 0 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .button {
              flex: 0 0 13.2rem;
              height: 4.4rem;
              font-size: 1.6rem;
              border: none;
              &.renew {
                background-color: #ddd;
                color: #999;
              }
              &.submit {
                background-color: #006369;
                color: #fff;
                margin-left: 1.6rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>