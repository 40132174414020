<template>
  <div class="policies-container">
    <div class="banner">
      <img src="@/assets/images/policies/banner.png" alt="banner" />
    </div>
    <div class="dyCarbon-container">
      <div class="top">
        <div class="title-container">
          <CoincidentText
            mainText="双碳政策"
            shadowText="Double Carbon Policies"
          ></CoincidentText>
        </div>
        <div class="more" @click="jump" data-path="doublecarbon">
          了解更多 <img src="@/assets/images/policies/enter.png" alt="enter" />
        </div>
      </div>
      <div class="bottom">
        <div class="item" v-for="(item, index) in dcPolicies" :key="index">
          <PolicyItem :obj="item" @toPolicyContent="toPolicyContent"></PolicyItem>
        </div>
      </div>
    </div>
    <div class="environKnow-container">
      <div class="top">
        <div class="title-container">
          <CoincidentText
          mainText="环保知乎"
          shadowText="Environmental Knowledge"
          ></CoincidentText>
        </div>
        <div class="more" @click="jump" data-path="environknowledge">
          了解更多 <img src="@/assets/images/policies/enter.png" alt="enter" />
        </div>
      </div>
      <div class="bottom">
        <div class="item" v-for="(item,index) in ekPolicies" :key="index">
          <PolicyItem :obj="item" @toPolicyContent="toPolicyContent"></PolicyItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoincidentText from "@/components/CoincidentText";
import PolicyItem from "@/components/PolicyItem";
import { getPolicies } from "@/utils/api.js";
export default {
  components: {
    CoincidentText,
    PolicyItem,
  },
  data() {
    return {
      dcPolicies: [],
      ekPolicies: [],
    };
  },
  methods: {
    toPolicyContent(id) {
      this.$emit("toPolicyContent", id);
    },
    jump(e){
      const path = e.currentTarget.getAttribute('data-path') 
      this.$emit("jump",path);
    }
  },
  mounted() {
    Promise.all([
      getPolicies({ type: 1, page: 1, page_size: 1 }),
      getPolicies({ type: 2, page: 1, page_size: 1 }),
      getPolicies({ type: 3, page: 1, page_size: 1 }),
      getPolicies({ type: 4, page: 1, page_size: 1 }),
    ]).then((res) => {
      const dcPolicies = res.map((item) => {
        const tempItem = item.data.body.data[0];
        if (tempItem.title.length > 28) {
          tempItem.sortTitle = tempItem.title.slice(0, 25) + "...";
        } else {
          tempItem.sortTitle = tempItem.title;
        }
        switch (tempItem.type) {
          case 1:
            tempItem.typeName = "政策规章";
            break;
          case 2:
            tempItem.typeName = "标准指南";
            break;
          case 3:
            tempItem.typeName = "新闻资讯";
            break;
          case 4:
            tempItem.typeName = "信息披露";
            break;
        }
        return tempItem;
      });
      this.dcPolicies = dcPolicies;
      console.log(dcPolicies);
    });
    Promise.all([
      getPolicies({ type: 101, page: 1, page_size: 1 }),
      getPolicies({ type: 102, page: 1, page_size: 1 }),
      getPolicies({ type: 103, page: 1, page_size: 1 }),
      getPolicies({ type: 104, page: 1, page_size: 1 }),
      getPolicies({ type: 105, page: 1, page_size: 1 }),
      getPolicies({ type: 106, page: 1, page_size: 1 }),
    ]).then((res) => {
      const ekPolicies = res.map((item) => {
        const tempItem = item.data.body.data[0];
        if (tempItem.title.length > 28) {
          tempItem.sortTitle = tempItem.title.slice(0, 23) + "...";
        } else {
          tempItem.sortTitle = tempItem.title;
        }
        switch (tempItem.type) {
          case 101:
            tempItem.typeName = "法律";
            break;
          case 102:
            tempItem.typeName = "法规";
            break;
          case 103:
            tempItem.typeName = "规章";
            break;
          case 104:
            tempItem.typeName = "国家标准";
            break;
          case 105:
            tempItem.typeName = "地方标准";
            break;
          case 106:
            tempItem.typeName = "规范性文件";
            break;
        }
        return tempItem;
      });
      this.ekPolicies = ekPolicies;
    });
  },
};
</script>

<style lang="less" scoped>
.policies-container {
  width: 100%;
  min-height: 198rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .banner img {
    margin-top: 6.2rem;
    width: 100%;
    flex: 0 0 43.6rem;
  }
  .dyCarbon-container {
    flex: 0 0 70rem;
    max-width: 120rem;
    display: flex;
    justify-content: center;
    align-content: space-evenly;
    flex-wrap: wrap;
    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-container {
        flex: 0 0 50rem;
      }
      .more {
        color: #006369;
        font-size: 2rem;
        font-weight: 500;
        cursor: pointer;
      }
    }
    .bottom {
      flex: 0 0 100%;
      min-height: 29rem;
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      .item {
        flex: 0 0 40%;
        max-width: 50rem;
        height: 9rem;
      }
    }
  }
  .environKnow-container {
    flex: 0 0 85rem;
    max-width: 120rem;
    display: flex;
    justify-content: center;
    align-content: space-evenly;
    flex-wrap: wrap;
    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-container {
        flex: 0 0 50rem;
      }
      .more {
        color: #006369;
        font-size: 2rem;
        font-weight: 500;
        cursor: pointer;
      }
    }
    .bottom {
      flex: 0 0 100%;
      min-height: 49rem;
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      .item {
        flex: 0 0 40%;
        max-width: 50rem;
        height: 9rem;
      }
    }
  }
}
</style>