<template>
  <div class="doubleCarbon-container">
    <div class="banner">
      <img src="@/assets/images/policies/banner.png" alt="banner" />
    </div>
    <div class="contents">
      <div class="routes">
        <div class="policies" @click="jump" data-path="policies">政策标准</div>
        <div class="next">></div>
        <div class="doubleCarbon">双碳政策</div>
      </div>
      <div class="navs">
        <div
          class="nav"
          :class="activeTag == 0 ? 'active' : ''"
          @click="changeTag"
          :data-tag="0"
        >
          政策规章
        </div>
        <div
          class="nav"
          :class="activeTag == 1 ? 'active' : ''"
          @click="changeTag"
          :data-tag="1"
        >
          标准指南
        </div>
        <div
          class="nav"
          :class="activeTag == 2 ? 'active' : ''"
          @click="changeTag"
          :data-tag="2"
        >
          新闻资讯
        </div>
        <div
          class="nav"
          :class="activeTag == 3 ? 'active' : ''"
          @click="changeTag"
          :data-tag="3"
        >
          信息披露
        </div>
      </div>
      <div class="lists">
        <div
          class="item"
          v-for="(item, index) in showLists[activeTag]"
          :key="index"
          @click="toContent"
          :data-id="item.id"
        >
          <div class="dot">■</div>
          <div class="context">{{ item.title }}</div>
        </div>
      </div>
      <div class="pagination">
        <Pagination
          :maxPage="maxPage[activeTag]"
          :current="currents[activeTag]"
          @pageChange="pageChange"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getPolicies } from "@/utils/api.js";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      activeTag: 0,
      currents: [1, 1, 1, 1],
      lists: [null, null, null, null],
      maxPage: [null, null, null, null],
    };
  },
  methods: {
    changeTag(e) {
      const tag = e.currentTarget.getAttribute("data-tag");
      this.activeTag = tag;
    },
    pageChange(current) {
      this.currents[this.activeTag] = current;
    },
    toContent(e) {
      const id = e.currentTarget.getAttribute("data-id");
      this.$emit("toPolicyContent", id);
    },
    jump(e) {
      const path = e.currentTarget.getAttribute("data-path");
      this.$emit("jump", path);
    },
  },
  mounted() {
    Promise.all([
      getPolicies({ page: 1, page_size: 9999, type: 1 }),
      getPolicies({ page: 1, page_size: 9999, type: 2 }),
      getPolicies({ page: 1, page_size: 9999, type: 3 }),
      getPolicies({ page: 1, page_size: 9999, type: 4 }),
    ]).then((res) => {
      const out = res.map((item) => {
        return item.data.body.data;
      });
      for (let i = 0; i < 4; i++) {
        this.lists[i] = out[i];
        this.maxPage[i] = Math.ceil(out[i].length / 7);
      }
    });
  },
  computed: {
    showLists() {
      console.log(this.lists);

      return this.lists.map((item, index) => {
        if (item) {
          return item.slice(
            7 * (this.currents[index] - 1),
            7 * this.currents[index]
          );
        } else {
          return null;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.doubleCarbon-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  .banner {
    flex: 0 0 100%;
    height: auto;
    margin-top: 6.2rem;
  }
  .banner img {
    width: 100%;
    max-height: 43.6rem;
  }
  .contents {
    flex: 0 0 100%;
    max-width: 120rem;
    min-height: 89rem;
    display: flex;
    justify-content: flex-start;
    align-content: space-evenly;
    flex-wrap: wrap;
    .routes {
      flex: 0 0 100%;
      display: flex;
      font-size: 2rem;
      justify-content: flex-start;
      align-items: center;
      .policies {
        cursor: pointer;
        color: #999;
      }
      .next {
        color: #999;
        margin: 0 2rem;
      }
      .doubleCarbon {
        color: #333;
      }
    }
    .navs {
      flex: 0 0 100%;
      flex-wrap: wrap;
      font-size: 2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: space-around;
      min-height: 2.9rem;
      .nav {
        cursor: pointer;
        height: 100%;
        border-bottom: 0.4rem solid #f4f4f5;
        padding: 0 2rem;
        color:#999;
        &.active {
          border-color: #006369;
          color: #006369;
        }
      }
    }
    .lists {
      flex: 0 0 100%;
      min-height: 35rem;
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      .item {
        flex: 0 0 100%;
        min-height: 5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #999;
        font-size: 1.2rem;
        cursor: pointer;
        .dot {
          margin-right: 2rem;
        }
      }
    }
    .pagination {
      flex: 0 0 100%;
      margin:5rem 0;
    }
  }
}
</style>